
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.mpCard {
  width: 100%;
  overflow: hidden;
  margin: 0.5%;
  cursor: pointer;

  @media (max-width: 1480px) {
    display: none;
  }

  img {
    border-radius: hz-radius(standard);
    width: 100%;
    height: auto;
    display: block;
  }

  &--mp-cards-homepage-one-tile {
    flex: 0 19%;
    margin: 0.5%;
    min-width: 168px;
    max-width: 240px;
  }

  &--mp-cards-homepage-two-tiles {
    flex: 0 39%;
  }

  &--mp-cards-homepage-top-banner {
    margin-bottom: hz-spacing(m);
  }
}

.mpCardBanner {
  cursor: pointer;

  @media (max-width: 1480px) {
    display: none;
  }

  img {
    border-radius: hz-radius(standard);
    width: 100%;
    height: auto;
    display: block;
  }
}
